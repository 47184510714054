<template>
  <div
    class="table-layout"
    :class="className"
    :style="{
      margin: margin
    }"
  >
    <Searchbar
      v-if="searchable"
      :name="name"
      :placeholder="searchPlaceholder"
      :search-function="searchFunction"
      @search="setSearchedStatus"
    />
    <b-overlay
      :show="isLoading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    >
      <Table
        :name="name"
        :fields="fields"
        :rows="rows"
        :route-to="routeTo"
        :default-sorting="defaultSorting"
        :sort-function="sortFunction"
        :selectable="selectable"
        :allow-deletion="allowDeletion"
        :allow-duplication="allowDuplication"
        :allow-modification="allowModification"
        :all-selected="allSelected"
        v-on="$listeners"
      />
      <Pagination
        v-if="count >= 50"
        :name="name"
        :nb-pages="Math.ceil(count / 50)"
        v-on="$listeners"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Pagination from '@/components/SearchablePaginatedTable/Pagination.vue';
import Searchbar from '@/components/SearchablePaginatedTable/Searchbar.vue';
import Table from '@/components/SearchablePaginatedTable/Table.vue';

export default {
  name: 'TableLayout',

  components: {
    Pagination,
    Searchbar,
    Table
  },

  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    margin: {
      type: String,
      default: ''
    },

    // searchbar
    searchable: {
      type: Boolean,
      default: true
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    searchFunction: {
      type: Function,
      default: () => { return {}; }
    },

    // table
    fields: {
      type: Array,
      default: () => { return []; }
    },
    rows: {
      type: Array,
      default: () => { return []; }
    },
    routeTo: {
      type: String,
      default: null
    },
    defaultSorting: {
      type: Object,
      default: () => {
        return {
          sortedUp: null,
          sortedDown: null,
          sortedField: null
        };
      }
    },
    sortFunction: {
      type: Function,
      default: () => { return {}; }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    allowDeletion: {
      type: Boolean,
      default: true
    },
    allowDuplication: {
      type: Boolean,
      default: false
    },
    allowModification: {
      type: Boolean,
      default: false
    },
    allSelected: {
      type: Boolean,
      default: false
    },

    // Pagination
    count: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isLoading: false,
      isTableSearched: false
    };
  },

  computed: {
    ...mapState('table', [
      'currentTables'
    ])
  },

  watch: {
    loading(newValue) {
      this.isLoading = newValue;
      this.SET_TABLE_LOADER({
        name: this.name,
        loading: newValue
      });
    },

    currentTables: {
      deep: true,
      handler(newValue) {
        const table = newValue.find(t => t.name === this.name);
        if (table && table.loading !== this.isLoading) {
          this.isLoading = table.loading;
        }
      }
    }
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE_LOADER'
    ]),

    setSearchedStatus(e) {
      this.isTableSearched = e;
    }
  }
};
</script>

<style lang="less">
.table-layout {
  margin: 1rem 3rem 1rem 2rem;

  .search-bar {
    margin: 0 0 0.5rem;
  }

  table {
    margin: 0;
    width: 100%;
  }
}
</style>
